import axios from 'axios'
import Cookies from 'js-cookie'

const api = async (method, url, params, isDownload = false) => {
	try {
		let config = { method, url }
		if (method === 'get') config.params = params
		if (method === 'post') config.data = params
		if (isDownload) config.responseType = 'blob'

		const { data } = await axios(config)
        
		if (data.status === 422) return { data, error: data.errors, status: false }
		if (data.status === 423) return { data, message: data.message, status: false }
		if (data.status === 401) {
			// unauthorized error
			Cookies.remove('token')
			location.reload()
		}

		return { data, status: true }
	} catch ({ request, response }) {
		if (response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			console.log(response)
            const { data } = response

            if (data) return { data, error: data.errors, status: false }
		}

		if (request) {
			// The request was made but no response was received
			console.log(request)
		}

		return { error: null, status: false }
	}
}

export default api
