import axios from 'axios'
import store from '../store'
// Request interceptor

axios.defaults.baseURL = process.env.VUE_APP_API_URL + process.env.VUE_APP_API_VERSION

axios.interceptors.request.use(request => {
	const token = store.state.token
    const tempToken = store.state.tempToken
    
	if (token) {
		request.headers.common['Authorization'] = `Bearer ` + token
	}

    if (tempToken) {
        request.headers.common['Authorization'] = `Bearer ` + tempToken
    }

	return request
})

axios.interceptors.response.use(response => response, error => {
	return Promise.reject(error)
})
