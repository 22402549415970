import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
Vue.use(VueI18n)

// const SUPPORTED_LOCALES = [
// 	'en', // english
// 	'ja', // japanese
// 	'cn' // japanese
// ]

function loadLocaleMessages () {
	const locales = require.context(
		'./locales',
		true,
		/[A-Za-z0-9-_,\s]+\.json$/i
	)
	const messages = {}
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			messages[locale] = locales(key)
		}
	})
	return messages
}

// function getBrowserLanguage () {
// 	const navigatorLocale =
// 			navigator.languages !== undefined
// 				? navigator.languages[0]
// 				: navigator.language

// 	const lang = navigatorLocale ? navigatorLocale.trim().split(/-|_/)[0] : 'cn'
// 	return lang
// }

function getCookiesLanguage () {
	const lang = Cookies.get('locale')
	return lang
}

function getDefaultLanguage () {
	const fromCookies = getCookiesLanguage()
	if (fromCookies) return fromCookies
	// const fromBrowser = getBrowserLanguage()
	// if (fromBrowser && SUPPORTED_LOCALES.includes(fromBrowser)) return fromBrowser
	return 'cn'
}

export default new VueI18n({
	locale: getDefaultLanguage(),
	fallbackLocale: 'cn',
	messages: loadLocaleMessages()
})
