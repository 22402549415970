import store from '../../store'

export default async (to, from, next) => {
	if (!store.getters.getLoggedInState) {
		next({ name: 'landing-page' })
	} else {
        if ((!store.getters.isSubscribed && !store.getters.hasExistingExam) && to.name !== 'packages') {
            if (to.name === 'terms' || to.name === 'policy' || to.name === 'terms') {
                next()
            } else {
                next({ name: 'packages'})
            }
        } else {
            next()
        }
	}
}
