import store from '../../store'

export default (to, from, next) => {
	if (store.getters.getLoggedInState) {
        if (!store.getters.isSubscribed && !store.getters.hasExistingExam) {
            next({ name: 'packages'})
        } else {
            next({ name: 'dashboard' })
        }
	} else {
		next()
	}
}
