export default [

	...middleware('guest', [
		{ path: '/', name: 'landing-page', component: () => import('@/pages/guest/LandingPage') },
		{ path: '/sign-in', name: 'sign-in', component: () => import('@/pages/guest/SignIn') },
		{ path: '/sign-up', name: 'sign-up', component: () => import('@/pages/guest/SignUp') },
		{ path: '/verify', name: 'verify', component: () => import('@/pages/guest/Verify') },
		{ path: '/reset/:token', name: 'reset', props: true, component: () => import('@/pages/guest/Reset') },
    ]),

	...middleware('auth', [
		{
			path: '/',
			component: () => import('@/pages/home/Home'),
			name: 'home',
			children: [
                {
					path: '/dashboard',
					name: 'dashboard',
					component: () => import('@/pages/home/Dashboard')
				},

                {
					path: '/results',
					name: 'results',
					component: () => import('@/pages/home/Results')
				},

				{
					path: '/packages',
					name: 'packages',
					component: () => import('@/pages/home/Packages')
				},

                {
					path: '/welcome',
					name: 'welcome',
					component: () => import('@/pages/home/Welcome')
				},

                {
					path: '/account',
					name: 'account',
					component: () => import('@/pages/home/Account')
				},

                {
					path: '/update-account',
					name: 'update-account',
					component: () => import('@/pages/home/UpdateAccount')
				},

                {
					path: '/change-password',
					name: 'change-password',
					component: () => import('@/pages/home/ChangePassword')
				},

                {
					path: '/card-assessment',
					name: 'card-assessment',
					component: () => import('@/pages/home/CardAssessment')
				},

                {
					path: '/card-summary',
					name: 'card-summary',
					component: () => import('@/pages/home/CardSummary')
				},

                {
					path: '/result-confirmation',
					name: 'result-confirmation',
					component: () => import('@/pages/home/ResultConfirmation')
				},

                {
					path: '/assessment-result/:id',
					name: 'assessment-result',
                    props: true,
					component: () => import('@/pages/home/AssessmentResult')
				},

                {
					path: '/contact-us',
					name: 'contact-us',
					component: () => import('@/pages/home/ContactUs')
				},

                {
					path: '/how-it-works',
					name: 'how-it-works',
					component: () => import('@/pages/home/HowItWorks')
				},
			]
		}
		
	]),

	...middleware('all', [
		{ path: '*', name: 'PageNotFound', component: () => import('@/pages/all/PageNotFound') },
        { path: '/terms', name: 'terms', component: () => import('@/pages/all/Terms') },
        { path: '/policy', name: 'policy', component: () => import('@/pages/all/PrivacyPolicy') },
        { path: '/cookies', name: 'cookies', component: () => import('@/pages/all/Cookies') },
		{ path: '/forgot-password', name: 'forgot-password', component: () => import('@/pages/all/ForgotPassword') },
	])

]

function middleware (middleware, routes) {
	routes.forEach(route =>
		(route.middleware || (route.middleware = [])).unshift(middleware)
	)

	return routes
}
