import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'

Vue.filter('capitalize', function (value) {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('null', function (value) {
	if (!value) return '-'
	else return value
})

Vue.filter("twoDigits", value => {
    if (value < 0) {
        return "00";
    }
    if (value.toString().length <= 1) {
        return `0${value}`;
    }
    return value;
})

Vue.filter('formatDateWithTime', function(value) {
    if (value) {
        return moment(String(value)).format('LLL')
    } else {
        return '-'
    }
})

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('ll')
    } else {
        return '-'
    }
})

Vue.filter('verifyEmail', async (email) => {
	let result = await axios({
		method: 'get',
		url: 'https://api.usebouncer.com/v1.1/email/verify',
		headers: { 'X-API-KEY': 'LB5Mvl0Xefy6uFhBhFGTVtiz6JA66e15Q2DWS5Jk' },
		params: { email: email }
	})

	return result.data.status === 'deliverable' || result.data.status === 'risky'
})

export default Vue
