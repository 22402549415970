import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import routes from './routes'
import { sync } from 'vuex-router-sync'
// import Nprogress from 'nprogress'

Vue.use(VueRouter)

const routeMiddleware = resolveMiddleware(

	require.context('./middleware', false, /.*\.js$/)

)

const router = make()

sync(store, router)

export default router

function resolveMiddleware (requireContext) {
	return requireContext.keys()
		.map(file =>
			[file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
		)
		.reduce((guards, [name, guard]) => (
			{ ...guards, [name]: guard.default }
		), {})
}

function make () {
	const router = new VueRouter({
		mode: 'history',
		routes: routes.map(beforeEnter)
	})

	router.beforeEach(async (to, from, next) => {
		if (!store.getters.getLoggedInState && store.state.token) {
			try {
				await store.dispatch('fetchUser')
			} catch (e) {
                console.log(e)
            }
		}
        
        if (store.getters.getLoggedInState && (!store.getters.isSubscribed && !store.getters.hasExistingExam) && to.name !== 'packages') {
            if (to.name === 'terms' || to.name === 'policy' || to.name === 'cookies') {
                next()
            } else {
                next({ name: 'packages'})
            }
        }

		// setTimeout(() => window.scrollTo(0, 0), 300)
		next()
	})

	// router.afterEach(() => Nprogress.done())

	return router
}

function beforeEnter (route) {
	if (route.children) {
		route.children.forEach(beforeEnter)
	}

	if (!route.middleware) {
		return route
	}

	route.beforeEnter = (...args) => {
		if (!Array.isArray(route.middleware)) {
			route.middleware = [route.middleware]
		}

		route.middleware.forEach(middleware => {
			if (typeof middleware === 'function') {
				middleware(...args)
			} else if (routeMiddleware[middleware]) {
				routeMiddleware[middleware](...args)
			} else {
				throw Error(`Undefined middleware [` + middleware + `]`)
			}
		})
	}

	return route
}
