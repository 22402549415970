<template>
    <div id="app" class="app">
        <keep-alive>
            <router-view class="main-router" />
        </keep-alive>
    </div>
</template>

<script>
// import { mapGetters, mapState } from 'vuex'
export default {
	name: 'App',

	async created () {
		// this.$store.dispatch('getCountries')
	},

	// computed: {
	// 	...mapGetters({
	// 		loggedInState: 'getLoggedInState'
	// 	}),

	// 	...mapState({
	// 		notificationStatus: state => state.banner.status
	// 	})
	// },

	data () {
		return {
			loaded: false
		}
	}
}
</script>

<style lang="scss" scoped>
#app {
	height: 100%;
	width: 100%;

	.main-router {
		overflow: auto;
	}

	.loading-modal {
		background-color: rgba(255, 255, 255, 0.4);

		/deep/ .modal-content {
			background: transparent;
			box-shadow: none;
		}
	}
}
</style>
