import { getField, updateField } from 'vuex-map-fields';
import Vue from 'vue'
import Vuex from 'vuex'
import api from './api'
import router from '@/router'
import Cookies from 'js-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        banner: {
            message: '',
            status: false
        },

        loading: false,
        user: null,
        tempUser: null,
        token: Cookies.get('token'),
        tempToken: null,

        language: Cookies.get('locale') || 'cn',

        signInError: {},
        signUpError: {},
        forgotError: {},
        changePassError: {},
        resetError: {},

        selectedCards: [],
        cards: [],
        reasons: [],
        completed: [],
        score: [
            {id:1, count: 0, order: ''},
            {id:2, count: 0, order: ''},
            {id:3, count: 0, order: ''},
            {id:4, count: 0, order: ''},
            {id:5, count: 0, order: ''},
            {id:6, count: 0, order: ''},
            {id:7, count: 0, order: ''},
            {id:8, count: 0, order: ''},
            {id:9, count: 0, order: ''},
            {id:10, count: 0, order: ''},
            {id:11, count: 0, order: ''},
            {id:12, count: 0, order: ''},
        ],
        hasTie: false,
        submittedAnswer: [],

        assessments: [],
        result: [],
        latestResult: [],
        plans: [],

        ableToAddFromReason: false
    },

    mutations: {
        updateField,

        TOGGLE_BANNER (state, { status, message = ''}) {
            state.banner.status = status
            state.banner.message = message || ''
        },

        SET_LOADING (state, payload) {
            this._vm.$set(state, 'loading', payload)
        },

        SET_USER (state, payload) {
            this._vm.$set(state, 'user', payload)
        },

        SET_TEMP_USER (state, payload) {
            this._vm.$set(state, 'tempUser', payload)
        },

        SET_TEMP_TOKEN (state, payload) {
            this._vm.$set(state, 'tempToken', payload)
        },

        SET_SUBSCRIPTION (state, payload) {
            this._vm.$set(state.user, 'subscription', payload)
        },
   
        // SET_CODE (state, payload) {
        //     this._vm.$set(state.tempUser, 'verification_code', payload)
        // },

        SET_TOKEN (state, payload) {
            this._vm.$set(state, 'token', payload)
        },

        SET_LANGUAGE (state, payload) {
            this._vm.$set(state, 'language', payload)
        },

        SET_SIGN_IN_ERROR (state, payload) {
            this._vm.$set(state, 'signInError', payload)
        },

        SET_SIGN_UP_ERROR (state, payload) {
            this._vm.$set(state, 'signUpError', payload)
        },

        SET_FORGOT_ERROR (state, payload) {
            this._vm.$set(state, 'forgotError', payload)
        },

        SET_CHANGE_PASS_ERROR (state, payload) {
            this._vm.$set(state, 'changePassError', payload)
        },

        SET_RESET_ERROR (state, payload) {
            this._vm.$set(state, 'resetError', payload)
        },

        SET_CARDS (state, payload) {
            this._vm.$set(state, 'cards', payload)
        },

        SET_REASONS (state, payload) {
            this._vm.$set(state, 'reasons', payload)
        },

        SET_ASSESSMENTS (state, payload) {
            this._vm.$set(state, 'assessments', payload)
        },

        SET_RESULT (state, payload) {
            this._vm.$set(state, 'result', payload)
        },

        SET_LATEST_RESULT (state, payload) {
            this._vm.$set(state, 'latestResult', payload)
        },

        SET_PLANS (state, payload) {
            this._vm.$set(state, 'plans', payload)
        },

        UPDATE_ACCOUNT (state, payload) {
            this._vm.$set(state.user, 'name', payload.name)
            this._vm.$set(state.user, 'organization', payload.organization)
            this._vm.$set(state.user, 'description', payload.description)
            this._vm.$set(state.user, 'profile_picture', payload.profile_picture)
        },

        UPDATE_TAKES (state, payload) {
            this._vm.$set(state.user.subscription, 'takes', payload)
        },

        CLEAR_GAME_VARIABLES (state) {
            this._vm.$set(state, 'selectedCards', [])
            this._vm.$set(state, 'completed', [])
            this._vm.$set(state, 'hasTie', false)
            this._vm.$set(state, 'reasons', [])
            this._vm.$set(state, 'submittedAnswer', [])
            this._vm.$set(state, 'score', [
                {id:1, count: 0, order: ''},
                {id:2, count: 0, order: ''},
                {id:3, count: 0, order: ''},
                {id:4, count: 0, order: ''},
                {id:5, count: 0, order: ''},
                {id:6, count: 0, order: ''},
                {id:7, count: 0, order: ''},
                {id:8, count: 0, order: ''},
                {id:9, count: 0, order: ''},
                {id:10, count: 0, order: ''},
                {id:11, count: 0, order: ''},
                {id:12, count: 0, order: ''},
            ])
        }
    },

    actions: {
        async fetchUser ({ commit }) {
            commit('SET_LOADING', true)
            const { data, status } = await api('get', '/web/profile/fetch-user')
    
            if (status) {
                commit('SET_USER', data.data)
                commit('SET_LOADING', false)
                return
            }
    
            commit('SET_USER', null)
            commit('SET_TOKEN', null)
            Cookies.remove('token')
            commit('SET_LOADING', false)
        },
        
        async signIn ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { data, error, status } = await api('post', '/web/authenticate/login', payload)
            
            if (status) {
                if (!data.data.email_verified_at && data.data.registration_type === 'online') {
                    // if email is not yet verified
                    commit('SET_TEMP_USER', data.data)
                    commit('SET_TEMP_TOKEN', data.data.api_token)
                    commit('SET_LOADING', false)
                    router.push('/verify')
                    return
                }
                
                commit('SET_USER', data.data)
                Cookies.set('token', data.data.api_token, { expires: 36500 })
                commit('SET_TOKEN', data.data.api_token)
                location.reload()
                return
            }

            commit('SET_SIGN_IN_ERROR', error || 'Something went wrong')
            commit('SET_USER', null)
            Cookies.remove('token')
            commit('SET_LOADING', false)
        },

        async signInWithClassmade ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { data, error, status } = await api('post', 'web/authenticate/classmade-login', payload)
            
            if (status) {
                if (!data.data.email_verified_at && data.data.registration_type === 'online') {
                    // if email is not yet verified
                    commit('SET_TEMP_USER', data.data)
                    commit('SET_TEMP_TOKEN', data.data.api_token)
                    commit('SET_LOADING', false)
                    router.push('/verify')
                    return
                }
                
                commit('SET_USER', data.data)
                Cookies.set('token', data.data.api_token, { expires: 36500 })
                commit('SET_TOKEN', data.data.api_token)
                location.reload()
                return
            }

            commit('SET_SIGN_IN_ERROR', error || 'Something went wrong')
            commit('SET_USER', null)
            Cookies.remove('token')
            commit('SET_LOADING', false)
        },

        async signUp ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { data, error, status } = await api('post', '/web/authenticate/signup', payload)

            if (status) {
                commit('SET_LOADING', false)
                commit('SET_TEMP_USER', data.data)
                commit('SET_TEMP_TOKEN', data.data.api_token)
                router.push({ path: '/verify' })
                return
            }
    
            commit('SET_SIGN_UP_ERROR', error)
            commit('SET_LOADING', false)
        },

        async checkEmail ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { status } = await api('post', '/web/authenticate/bouncer', payload)
    
            commit('SET_LOADING', false)
            return status
        },

        async verify ({ state, commit }, payload) {
            commit('SET_LOADING', true)
            const { status } = await api('post', '/web/authenticate/verify-code', payload)
            
            if (status) {
                commit('SET_USER', state.tempUser)
                Cookies.set('token', state.tempUser.api_token, { expires: 36500 })
                commit('SET_TOKEN', state.tempUser.api_token)
                commit('SET_TEMP_USER', null)
                commit('SET_TEMP_TOKEN', null)
                location.reload()
                // router.push({ path: '/dashboard'})
                return true
            }

            // commit('SET_SIGN_IN_ERROR', error || 'Something went wrong')
            // commit('SET_USER', null)
            // Cookies.remove('token')
            commit('SET_LOADING', false)
            return false
        },

        async forgotPassword ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { error, status } = await api('post', '/web/authenticate/forgot-password', payload)
    
            if (status) {
                commit('TOGGLE_BANNER', {
                    status: true,
                    message: 'Please check your email.'
                })

                commit('SET_FORGOT_ERROR', {})
                commit('SET_LOADING', false)
                // router.push({ path: '/verify' })
                return
            }
    
            commit('SET_FORGOT_ERROR', error)
            commit('SET_LOADING', false)
        },

        async resendCode ({ commit }) {
            commit('SET_LOADING', true)
            const { status } = await api('get', '/web/authenticate/resend-code')
    
            if (status) {
                // console.log(data.data)
                // commit('SET_CODE', data.data.verification_code)
                commit('SET_LOADING', false)
                return
            }
    
            // commit('SET_CARDS', [])
            commit('SET_LOADING', false)
        },

        async logout ({ commit }) {
            // await api('post', '/insight/authenticate/logout')
            commit('SET_TEMP_USER', null)
            commit('SET_TEMP_TOKEN', null)
            commit('SET_USER', null)
            commit('SET_TOKEN', null)
            Cookies.remove('token')
            location.reload()
        },

        async getCards ({ commit }) {
            commit('SET_LOADING', true)
            const { data, status } = await api('get', '/web/assessment/view-cards')
    
            if (status) {
                commit('SET_CARDS', data.data)
                commit('SET_LOADING', false)
                return
            }
    
            commit('SET_CARDS', [])
            commit('SET_LOADING', false)
        },

        async getReasons ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { data, status } = await api('get', '/web/assessment/view-reasons', payload)
    
            if (status) {
                commit('SET_REASONS', data.data)
                commit('SET_LOADING', false)
                return
            }
    
            commit('SET_REASONS', [])
            commit('SET_LOADING', false)
        },

        async getResult ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { data, status } = await api('get', '/web/assessment/view-result', payload)
    
            if (status) {
                commit('SET_RESULT', data.data)
                commit('SET_LOADING', false)
                return
            }
    
            commit('SET_REASONS', [])
            commit('SET_LOADING', false)
        },

        async getLatestResult ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { data, status } = await api('get', '/web/assessment/latest-result', payload)
    
            if (status) {
                commit('SET_LATEST_RESULT', data.data)
                commit('SET_LOADING', false)
                return
            }
    
            commit('SET_LATEST_RESULT', [])
            commit('SET_LOADING', false)
        },

        async getPlans ({ commit }) {
            commit('SET_LOADING', true)
            const { data, status } = await api('get', '/web/subscribe/view-plans')
    
            if (status) {
                commit('SET_PLANS', data.data)
                commit('SET_LOADING', false)
                return
            }
    
            commit('SET_PLANS', [])
            commit('SET_LOADING', false)
        },

        async getAssessments ({ commit }) {
            commit('SET_LOADING', true)
            const { data, status } = await api('get', '/web/assessment/assessments')
    
            if (status) {
                commit('SET_ASSESSMENTS', data.data)
                commit('SET_LOADING', false)
                return
            }
    
            commit('SET_ASSESSMENTS', [])
            commit('SET_LOADING', false)
        },

        async takesChecker ({ commit }) {
            commit('SET_LOADING', true)
            const { data, status } = await api('get', '/web/assessment/check-takes')
    
            if (status) {
                commit('SET_LOADING', false)
                return { data: data.data }
            }
    
            commit('SET_LOADING', false)
            return false
        },

        async cancelAssessment ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { data, status } = await api('post', '/web/assessment/cancel-assessment', payload)
    
            if (status) {
                commit('UPDATE_TAKES', data.data.takes)
                commit('SET_LOADING', false)
                return true
            }
    
            commit('SET_LOADING', false)
            return false
        },

        async subscribe ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { data, status } = await api('post', '/web/subscribe/subscription', payload)
    
            if (status) {
                commit('SET_SUBSCRIPTION', data.data)
                commit('SET_LOADING', false)
                return {status: true, end_date: data.data.end_date}
            }

            commit('SET_LOADING', false)
            return {status: false}
        },

        async updateAccount ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { data, status } = await api('post', '/web/profile/update-profile', payload)
    
            if (status) {
                commit('UPDATE_ACCOUNT', data.data)
                commit('TOGGLE_BANNER', {
                    status: true,
                    message: 'You successfully update your profile.'
                })
                commit('SET_LOADING', false)
                router.push('/account')
            }

            commit('SET_LOADING', false)
        },

        async changePassword ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { error, status } = await api('post', '/web/profile/change-password', payload)
    
            if (status) {
                commit('TOGGLE_BANNER', {
                    status: true,
                    message: 'You successfully update your password.'
                })
                commit('SET_CHANGE_PASS_ERROR', {})
                commit('SET_LOADING', false)
                router.push('/account')
                return
            }

            commit('SET_CHANGE_PASS_ERROR', error)
            commit('SET_LOADING', false)
        },

        async resetPassword ({ commit }, payload) {
            commit('SET_LOADING', true)
            const { error, status } = await api('post', '/web/authenticate/reset-password', payload)
    
            if (status) {
                commit('TOGGLE_BANNER', {
                    status: true,
                    message: 'You successfully reset your password.'
                })
                commit('SET_RESET_ERROR', {})
                commit('SET_LOADING', false)
                router.push('/sign-in')
                return
            }

            commit('SET_RESET_ERROR', error)
            commit('SET_LOADING', false)
        },

        async submitExam ({ commit, state }, payload) {
            commit('SET_LOADING', true)
            const { data, status } = await api('post', '/web/assessment/assessment-exam', payload)
    
            if (status) {
                // console.log(data.data)
                commit('UPDATE_TAKES', state.user.subscription.takes - 1)
                commit('SET_LOADING', false)
                router.push({ name: 'assessment-result', params: {id: data.data[0].assessment_id} })
                return
            }

            commit('SET_LOADING', false)
        },
    },

    getters: {
        getField,

        getLoggedInState: state => state.user !== null,
        isSubscribed: state => state.user.subscription.id,
        hasExistingExam: state => state.user.is_assessed
    },

    modules: {
    }
})
